import { storyblokEditable } from "@storyblok/react/rsc";
import { SeedWave } from "~components/ui/seed-wave";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { TextSectionBlok } from "./types";

type TextSectionProps = {
  blok: TextSectionBlok;
} & PageContext;

export function TextSection({ blok, ...context }: TextSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="text"
      className={cn(
        blok.theme,
        "section relative min-h-screen w-full place-content-center overflow-hidden text-content-text",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "accent" && "bg-surface/accent",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
      )}
      {...storyblokEditable(blok)}
    >
      <SeedWave
        className="section-item-full absolute top-[0] z-[2] h-full w-full bg-decor/100"
        seedPatternColor={blok.seedPatternColor}
      />

      <div
        className={cn(
          "z-10 m-auto flex w-full flex-col place-items-center gap-sm text-center",
        )}
      >
        <h1 className="flex w-full max-w-title flex-col text-pretty text-content-heading text-strong-3xl">
          {blok.title}
        </h1>

        <RichText
          data={blok.text}
          classNames={{
            paragraph:
              "t-strong-lg max-w-text/sm text-pretty text-content-text",
          }}
          {...context}
        />
      </div>
    </section>
  );
}
