"use client";

import Image from "next/image";
import Script from "next/script";
import { createRef, useEffect, useId, useState } from "react";
import { type PageContext, buildImageLoader } from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { HubspotFormSectionBlok } from "./types";

type HubspotFormSectionProps = {
  blok: HubspotFormSectionBlok;
} & PageContext;

declare global {
  interface Window {
    hbspt: {
      forms: {
        create(opts: {
          region: string;
          portalId: string;
          formId: string;
          target: Element | string;
        }): void;
      };
    };
  }
}

export function HubspotFormSection({
  blok,
  ...context
}: HubspotFormSectionProps) {
  const id = useId();

  // Build a prefixed, cleaner id for the html element.
  const targetId = `hbspot-${id.replace(/:/g, "")}`;

  const [isLibLoaded, setIsLibLoaded] = useState(false);
  const embedRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!isLibLoaded || !targetId) return;

    window.hbspt.forms.create({
      region: blok.hubspotRegion,
      portalId: blok.hubspotPortalId,
      formId: blok.hubspotFormId,
      target: `#${targetId}`,
    });
  }, [targetId, blok, isLibLoaded]);

  return (
    <>
      <Script
        type="text/javascript"
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
        onLoad={() => setIsLibLoaded(true)}
      />

      <section
        id={blok.sectionId}
        data-section-type="hubspot-form"
        className={cn(
          "section-sky grid justify-items-center text-content-text",
          blok.variant === "default"
            ? "bg-surface/100 px-sm py-2xl-3xl md:px-3xl-4xl"
            : "bg-surface/contrast lg:grid-cols-2",
        )}
      >
        <div
          className={cn(
            "flex w-full max-w-[34em] flex-col place-items-center gap-lg-xl px-sm md:px-lg-xl",
            blok.variant === "default"
              ? "rounded-lg bg-surface/contrast py-xl-2xl pb-lg-xl"
              : "py-4xl",
          )}
        >
          <div className="flex flex-col gap-sm">
            {blok.title && (
              <h1 className="t-strong-3xl max-w-title text-balance text-content-heading">
                {blok.title}
              </h1>
            )}

            {blok.text && (
              <RichText
                className="t-default-md max-w-text text-pretty leading-normal"
                data={blok.text}
                {...context}
              />
            )}
          </div>

          <div ref={embedRef} id={targetId} className={cn("w-full")} />
        </div>

        {blok.backgroundImage?.filename && (
          <>
            <Image
              src={blok.backgroundImage.filename}
              alt={blok.backgroundImage.alt}
              width={400}
              height={300}
              loader={buildImageLoader({
                aspectRatio: 4 / 3,
                focus: blok.backgroundImage.focus,
              })}
              className="w-full lg:hidden"
            />

            <Image
              src={blok.backgroundImage.filename}
              alt={blok.backgroundImage.alt}
              width={700}
              height={1000}
              loader={buildImageLoader({
                aspectRatio: 7 / 10,
                focus: blok.backgroundImage.focus,
              })}
              className="sticky top-[0] h-screen w-full object-cover max-lg:hidden"
            />
          </>
        )}
      </section>
    </>
  );
}
