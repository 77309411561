import Image from "next/image";
import { buildImageLoader, getFilenameDimensions } from "~lib/storyblok";
import { cn } from "~utils";
import type { FigureBlok } from "./types";

import { render } from "storyblok-rich-text-react-renderer";

type FigureProps = {
  blok: FigureBlok;
  captionClassName?: string;
  className?: string;
  sizes?: string;
};

export function Figure({
  blok,
  captionClassName,
  className,
  sizes,
}: FigureProps) {
  return (
    <figure className={cn("flex flex-col gap-y-2xs", className)}>
      <Image
        {...getFilenameDimensions(blok.image.filename)}
        sizes={sizes ?? "100vw"}
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 16 / 9,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        title={blok.image.title ?? undefined}
        className="overflow-hidden rounded-lg"
      />

      <figcaption
        className={cn(
          "t-prosi-xs border-border/300 border-b-[1px] pb-2xs",
          captionClassName,
        )}
      >
        {render(blok.caption)}
      </figcaption>
    </figure>
  );
}
