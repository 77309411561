import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn, getFlagIcon } from "~utils";
import type { MigrationPersonGridSectionBlok } from "./types";

type MigrationPersonGridSectionProps = {
  blok: MigrationPersonGridSectionBlok;
} & PageContext;

export function MigrationPersonGridSection({
  blok,
  ...context
}: MigrationPersonGridSectionProps) {
  const items = blok.items?.length
    ? blok.items
    : blok.references?.map(reference => reference.content).filter(Boolean);

  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-person-grid"
      className={cn(
        blok.theme ? blok.theme : "section-sky bg-others-white",
        blok.theme && "bg-surface/100",
        "flex flex-col text-content-heading",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="m-auto grid w-full max-w-screen-xl gap-lg-xl px-sm py-2xl md:px-lg-xl">
        <h2 className="t-strong-3xl m-auto max-w-[25ch] text-balance text-center text-content-heading">
          {blok.title}
        </h2>

        <div className="grid grid-cols-[repeat(auto-fit,minmax(12em,1fr))] place-items-start gap-sm text-content-text">
          {items.map(item => (
            <a
              key={item._uid}
              className="grid gap-xs"
              {...getLinkProps(item.link, context)}
            >
              <Image
                width="600"
                height="600"
                sizes="(max-width: 1024px) 100vw, 360px"
                src={item.image.filename ?? ""}
                loader={buildImageLoader({
                  aspectRatio: 1 / 1,
                  focus: item.image.focus,
                })}
                alt={item.image.alt ?? ""}
                title={item.image.title ?? ""}
                className="overflow-hidden rounded-sm"
              />

              <div className="grid gap-3xs">
                <div className="flex place-items-center gap-2xs">
                  <h3 className="t-strong-sm leading-tight">{item.name}</h3>

                  {getFlagIcon(item.nationality) && (
                    <Image
                      width="40"
                      height="40"
                      src={getFlagIcon(item.nationality)}
                      alt={item.nationality}
                      className="h-[1em] w-[1em] rounded-full"
                    />
                  )}
                </div>
                <p className="t-strong-base leading-tight">{item.role}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}
