import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { useState } from "react";

import { Button, Icon, Rails } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import type { SplitSectionBlok, SplitSectionItem } from ".";
import { RichText } from "../rich-text";

type SplitSectionProps = {
  blok: SplitSectionBlok;
} & PageContext;

export function SplitSection({ blok, ...context }: SplitSectionProps) {
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  return (
    <Rails.Root
      id={blok.sectionId}
      data-section-type="split"
      className={cn(
        blok.theme,
        blok.pattern && "pb-[20em]",
        "section relative bg-surface/200",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
        blok.backgroundVariant === "white" && "bg-others-white",
      )}
      {...storyblokEditable(blok)}
    >
      {blok.pattern && (
        <SeedWave
          className="section-item-full absolute top-[0] h-full w-full bg-decor/accent"
          seedPatternColor={blok.patternColor}
        />
      )}

      <div
        className={cn(
          "grid grid-cols-1 place-content-center place-items-center gap-xl-2xl",
          blok.flipped
            ? "lg:grid-cols-[auto,minmax(auto,18em)] xl:grid-cols-[1fr,minmax(auto,22em)]"
            : "lg:grid-cols-[minmax(auto,18em),auto] xl:grid-cols-[minmax(auto,22em),1fr]",
        )}
      >
        <div
          className={cn(
            "flex flex-col place-content-center gap-sm max-lg:mr-auto",
            // Always flip the text on mobile, as we want the image on top
            blok.flipped ? "order-2" : "max-lg:order-2",
          )}
        >
          {blok.leading && (
            <h3 className="t-prosi-xl max-w-title text-content-heading">
              {blok.leading}
            </h3>
          )}

          <h2 className="t-strong-3xl max-w-title/xs text-balance text-content-heading">
            {blok.title}
          </h2>

          {blok.text && (
            <div className="flex max-w-sub flex-col gap-sm">
              <RichText
                data={blok.text}
                classNames={{
                  paragraph: "t-strong-lg text-pretty text-content-text",
                }}
                {...context}
              />
            </div>
          )}

          {blok.ctaLabel && blok.ctaLink && (
            <div className="flex">
              <Button
                arrow
                className="inline-block"
                {...getLinkProps(blok.ctaLink, context)}
              >
                {blok.ctaLabel}
              </Button>
            </div>
          )}
        </div>

        {blok.image.filename && (
          <div className="relative flex w-full flex-col items-center justify-center">
            <Image
              sizes="100vw"
              src={blok.image.filename}
              loader={buildImageLoader({
                aspectRatio: 1,
                focus: blok.image.focus,
              })}
              className="w-full rounded-lg lg:hidden lg:max-w-[min(24em,100%)]"
              alt={blok.image.alt}
              title={blok.image.title ?? undefined}
              width={400}
              height={400}
            />

            <Image
              sizes="33vw"
              src={blok.image.filename}
              loader={buildImageLoader({
                aspectRatio: 1,
                focus: blok.image.focus,
              })}
              alt={blok.image.alt}
              className="w-full max-w-[32em] rounded-lg max-lg:hidden"
              title={blok.image.title ?? undefined}
              width={800}
              height={800}
            />

            {!blok.disableImagePattern && (
              <div
                className={cn(
                  "absolute z-10 h-full w-full max-w-[min(24em,100%)] bg-surface/200 lg:max-w-[32em]",
                  "bottom-[-1px] left-[-1px] [mask-image:url(/masks/seed-corner.svg)] [mask-size:cover]",
                  blok.backgroundVariant === "darker" && "bg-surface/300",
                  blok.backgroundVariant === "lighter" && "bg-surface/100",
                  blok.backgroundVariant === "white" && "bg-others-white",
                )}
              />
            )}
          </div>
        )}
      </div>

      {blok.items?.length ? (
        <>
          <div
            className={cn(
              "flex gap-sm md:place-self-end",
              // Only hide on xl, as on smaller screens hiding can cause "jumps"
              isPrevDisabled && isNextDisabled && "xl:hidden",
              blok.stackItemsOnMobile && "max-sm:hidden",
            )}
          >
            <Rails.Control
              action="prev"
              isDisabled={isPrevDisabled}
              onDisabledChanged={setIsPrevDisabled}
            />
            <Rails.Control
              action="next"
              isDisabled={isNextDisabled}
              onDisabledChanged={setIsNextDisabled}
            />
          </div>

          <Rails.Viewport
            className={cn(
              "section-item-full z-10 flex gap-lg overflow-x-auto",
              "scrollbar-hidden z-10 snap-x snap-mandatory scroll-px-[var(--section-offset-x)] px-[var(--section-offset-x)]",
              blok.stackItemsOnMobile && "max-sm:hidden",
            )}
          >
            {blok.items.map(item => (
              <Rails.Item
                key={item._uid}
                className={cn(
                  "flex w-[14.333em] flex-shrink-0 snap-center flex-col md:w-[13em] md:snap-start",
                  blok.variant === "icons" ? "gap-xs" : "gap-lg",
                )}
                {...storyblokEditable(item as SbBlokData)}
              >
                <SplitSectionItemBody
                  variant={blok.variant}
                  item={item}
                  context={context}
                />
              </Rails.Item>
            ))}
          </Rails.Viewport>

          {blok.stackItemsOnMobile &&
            blok.items.map(item => (
              <div
                key={item._uid}
                className={cn(
                  "flex flex-col sm:hidden",
                  blok.variant === "icons" ? "gap-xs" : "gap-lg",
                )}
              >
                <SplitSectionItemBody
                  variant={blok.variant}
                  item={item}
                  context={context}
                />
              </div>
            ))}
        </>
      ) : null}
    </Rails.Root>
  );
}

type SplitSectionItemBodyProps = {
  variant?: string;
  item: SplitSectionItem;
  context: PageContext;
};

const SplitSectionItemBody = ({
  variant,
  item,
  context,
}: SplitSectionItemBodyProps) => {
  return (
    <>
      {variant === "icons" && item.icon && (
        <Icon name={item.icon} weight="duotone" className="circle-icon" />
      )}

      {(!variant || variant === "default") && item.image?.filename && (
        <Image
          src={item.image.filename}
          width="260"
          height="260"
          loader={buildImageLoader({
            aspectRatio: 1,
            focus: item.image.focus,
          })}
          className="overflow-hidden rounded-sm bg-surface/100"
          alt={item.image.alt}
        />
      )}
      <div>
        <h3
          className={cn(
            "text-content-heading",
            variant === "icons"
              ? "t-strong-lg mb-2xs"
              : "t-strong-xl leading-relaxed",
          )}
        >
          {item.title}
        </h3>
        <RichText data={item.text} {...context} />
      </div>
    </>
  );
};
