import { type ISbRichtext, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import type { ReactNode } from "react";
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  render,
} from "storyblok-rich-text-react-renderer";

import {
  buildImageLoader,
  getFilenameDimensions,
  getLinkMarkProps,
  getReadingTime,
  imageLoader,
  isWrapperNode,
} from "~lib/storyblok";
import type { PageContext } from "~lib/storyblok/page";
import { cn, formatDate, formatMinutes } from "~utils";

import { SeedWave } from "~components/ui/seed-wave";
import { Figure, type FigureBlok, type ImageGalleryBlok } from "../rich-text";
import { ImageGallery } from "../rich-text/image-gallery";
import type { ArticlePageBlok } from "./types";

type ArticlePageProps = {
  blok: ArticlePageBlok;
} & PageContext;

export function ArticlePage({ blok, ...context }: ArticlePageProps) {
  const readingTime = getReadingTime(blok.text);

  if (blok.isDisabled) {
    return <code className="flex p-lg">Article disabled for this market</code>;
  }

  if (blok.externalLink?.url)
    return (
      <code>
        external link:
        <br /> {blok.externalLink.url}
      </code>
    );

  return (
    <main
      {...storyblokEditable(blok)}
      data-page-type="article"
      className={cn(
        blok.theme ? blok.theme : "section-sky bg-others-white",
        blok.theme && "bg-surface/100",
        "t-default-sm text-content-text leading-relaxed",
      )}
    >
      <div className="px-xs pt-3xl-4xl pb-2xl">
        <div className="mx-auto mb-base grid w-full max-w-[40rem] gap-2xs">
          <div className="t-default-sm flex place-items-center gap-[1ch]">
            <span>
              {formatDate(blok.date || context.meta.created_at, context.locale)}
            </span>
            <span className="mb-[0.5em] font-extrabold">.</span>
            <span>{formatMinutes(readingTime?.minutes, context.locale)}</span>
          </div>

          <h1 className="t-strong-3xl">{blok.title}</h1>

          {blok.author?.content?.name && (
            <div className="t-prosi-sm inline-flex place-items-center gap-2xs">
              <div className="overflow-hidden rounded-full">
                {blok.author.content.image.filename && (
                  <Image
                    width="40"
                    height="40"
                    sizes="40px"
                    loader={buildImageLoader({ aspectRatio: 1 / 1 })}
                    src={blok.author.content.image.filename}
                    alt={blok.author.content.image.alt}
                  />
                )}
              </div>

              <span>{blok.author.content.name}</span>
            </div>
          )}
        </div>

        {blok.image && (
          <div className="relative mx-auto my-lg grid max-w-[58em] overflow-hidden rounded-lg">
            {blok.image.filename && (
              <Image
                src={blok.image.filename}
                priority
                width={1600}
                height={900}
                loader={buildImageLoader({
                  aspectRatio: 16 / 9,
                  focus: blok.image.focus,
                })}
                sizes="100vw"
                alt={blok.image.alt ?? ""}
                className="col-[1/2] row-[1/3]"
              />
            )}

            <SeedWave
              variant="xl"
              className="section-item-full absolute top-[0] h-full w-full bg-decor/accent"
            />
          </div>
        )}

        <ArticlePageBody text={blok.text} {...context} />
      </div>
    </main>
  );
}

type ArticlePageBodyProps = {
  text: ISbRichtext;
} & PageContext;

function ArticlePageBody({ text, ...context }: ArticlePageBodyProps) {
  let isFirstParagraph = true;

  return render(text, {
    blokResolvers: {
      Figure: props => (
        <Figure
          blok={props as FigureBlok}
          className="mx-auto my-base max-w-[44rem]"
          sizes="(min-width: 1024px) 1200px, 100vw"
          captionClassName="w-full max-w-[40rem] mx-auto"
        />
      ),
      ImageGallery: props => (
        <ImageGallery
          blok={props as ImageGalleryBlok}
          className="mx-auto my-base max-w-[44rem]"
        />
      ),
    },
    markResolvers: {
      [MARK_LINK]: (children, props) => (
        <a {...getLinkMarkProps(props, context)}>{children}</a>
      ),
    },
    nodeResolvers: {
      [NODE_IMAGE]: (_children, { alt, src, title }) => (
        <Image
          {...getFilenameDimensions(src)}
          sizes="(max-width: 1200px) 100vw, 940px"
          src={src ?? ""}
          loader={imageLoader}
          alt={alt ?? ""}
          title={title}
          className="mx-auto my-base w-full max-w-[48em] overflow-hidden rounded-lg"
        />
      ),
      [NODE_PARAGRAPH]: children => {
        // Unwrap elements when there is no need for extra paragraph
        if (isWrapperNode(children)) return <>{children}</>;

        const className = cn(
          "mx-auto mb-sm w-full max-w-[40rem] text-pretty [&_a]:text-content-link [&_a]:underline",
          isFirstParagraph && "t-prose-md",
        );

        isFirstParagraph = false;

        return <p className={className}>{children}</p>;
      },
      [NODE_HEADING]: (children, { level }) => {
        const wrapper = (heading: ReactNode) => (
          <div className="mx-auto my-lg mb-sm w-full max-w-[40rem]">
            {heading}
          </div>
        );

        switch (level) {
          case 3:
            return wrapper(
              <h3
                className={
                  "t-strong-xl max-w-[45ch] text-balance leading-tight"
                }
              >
                {children}
              </h3>,
            );
          case 4:
            return wrapper(
              <h4
                className={
                  "t-strong-xl max-w-[45ch] text-balance leading-tight"
                }
              >
                {children}
              </h4>,
            );
          case 5:
            return wrapper(
              <h5
                className={
                  "t-strong-xl max-w-[45ch] text-balance leading-tight"
                }
              >
                {children}
              </h5>,
            );
          default:
            return wrapper(
              <h2
                className={
                  "t-strong-xl max-w-[45ch] text-balance leading-tight"
                }
              >
                {children}
              </h2>,
            );
        }
      },
      [NODE_QUOTE]: children => (
        <blockquote className="t-prosi-md mx-auto my-lg w-full max-w-[40rem] text-balance border-content-link border-l-2 pl-lg">
          {children}
        </blockquote>
      ),
    },
  });
}
