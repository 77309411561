"use client";

import { apiPlugin, storyblokInit } from "@storyblok/react/rsc";
import { AgreenaSection } from "./agreena-section";
import { ArticlePage } from "./article-page";
import { BenefitsSection } from "./benefits-section";
import { CarbonCreditsSection } from "./carbon-credits-section";
import { ContactSection } from "./contact-section";
import { CropDropSection } from "./crop-drop-section";
import { DocPage } from "./doc-page";
import { DynamicPage } from "./dynamic-page";
import { EventPage } from "./event-page";
import { FeaturesSection } from "./features-section";
import { GridSection } from "./grid-section";
import { HeroSection } from "./hero-section";
import { HubspotFormSection } from "./hubspot-form-section";
import { ImageSection } from "./image-section";
import { InfoPage } from "./info-page";
import { LinkSection } from "./link-section";
import { LogoSection } from "./logo-section";
import { MapSection } from "./map-section";
import { MigrationExpandableTilesSection } from "./migration-expandable-tiles-section";
import { MigrationExpandableTile } from "./migration-expandable-tiles-section/migration-expandable-tile";
import { MigrationHelpSection } from "./migration-help-section";
import { MigrationHeroSection } from "./migration-hero-section";
import { MigrationInvestorsSection } from "./migration-investors-section";
import { MigrationMissionSection } from "./migration-mission-section";
import { MigrationPersonDetailsCardsSection } from "./migration-person-details-cards-section";
import { MigrationPersonGridSection } from "./migration-person-grid-section";
import { MigrationSideBySideSection } from "./migration-side-by-side-section";
import { MigrationTextSection } from "./migration-text-section";
import { MigrationVideoSection } from "./migration-video-section";
import { NewsPage } from "./news-page";
import { PitchItem, PitchSection } from "./pitch-section";
import { PressReleasePage } from "./press-release-page";
import { QuoteSection } from "./quote-section";
import { SeedSection } from "./seed-section";
import { ShowcaseSection } from "./showcase-section";
import { SlidesSection } from "./slides-section";
import { SplitSection } from "./split-section";
import { TextSection } from "./text-section";
import { TileSection } from "./tile-section";
import { TypeformSection } from "./typeform-section";

/**
 * Initialises storyblok synchronously so we can:
 * 1. Render components both for server and client
 * 2. Live edit using Storyblok "bridge" when inside the editor.
 */
storyblokInit({
  accessToken: process.env.STORYBLOK_PREVIEW_TOKEN,
  use: [apiPlugin],
  components: {
    AgreenaSection,
    ArticlePage,
    BenefitsSection,
    CarbonCreditsSection,
    Config: () => null,
    ContactSection,
    CropDropSection,
    DocPage,
    DynamicPage,
    EventPage,
    FeaturesSection,
    GridSection,
    HeroSection,
    HubspotFormSection,
    ImageSection,
    InfoPage,
    SeedSection,
    LinkSection,
    LogoSection,
    MapSection,
    MigrationExpandableTile,
    MigrationExpandableTilesSection,
    MigrationHeroSection,
    MigrationMissionSection,
    MigrationPersonDetailsCardsSection,
    MigrationPersonGridSection,
    MigrationSideBySideSection,
    MigrationVideoSection,
    MigrationInvestorsSection,
    MigrationHelpSection,
    MigrationTextSection,
    NewsPage,
    PitchItem,
    PitchSection,
    PressReleasePage,
    QuoteSection,
    ShowcaseSection,
    SlidesSection,
    SplitSection,
    TileSection,
    TextSection,
    TypeformSection,
  },
});

type StoryblokProviderProps = {
  children: React.ReactElement;
};

export default function StoryblokProvider({
  children,
}: StoryblokProviderProps) {
  return children;
}
